@if (title) {
    <div class="skeleton title-carousel"></div>
}
<div class="categories">
    <div class="skeleton category"></div>
    <div class="skeleton category"></div>
    <div class="skeleton category"></div>
</div>
@if(pagination) {
    <div class="footer">
        <div class="skeleton bullets"></div>
        <div class="buttons">
            <div class="skeleton button"></div>
            <div class="skeleton button"></div>
        </div>
    </div>
} @else {
    <div class="buttons">
        <div class="skeleton button"></div>
        <div class="skeleton button"></div>
    </div>
}
